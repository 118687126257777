import {
  DailyMachineReport,
  ICampaignJsonDetailReportDict,
  ICampaignReportDict,
  MachineDetailedValues,
  MachinesMeasurementValue,
  MachinesOperatingValue,
  OperatingAnalysisList,
  PaginatedMachines,
} from '@app/shared/models/analytics.model';
import { Machine, MachineBase } from '@app/shared/models/machines.model';
import { StateError } from '..';
import * as AnalyticsActions from './analytics.actions';

export interface IMachineDict {
  [key: string]: MachineBase;
}

export interface IMachineDetailedValuesDict {
  [key: string]: MachineDetailedValues;
}

export interface State {
  machinesList: Machine[] | null;
  machinesListPending: boolean;
  machinesListError: StateError;
  machines: IMachineDict;
  machinePending: boolean;
  machineError: StateError;
  measurementValues: MachinesMeasurementValue[];
  measurementValuesPending: boolean;
  operatingValues: MachinesOperatingValue[];
  operatingValuesPending: boolean;
  operatingAnalysis: OperatingAnalysisList | null;
  operatingAnalysisPending: boolean;
  operatingAnalysisError: StateError;
  machineDetailedValues: IMachineDetailedValuesDict | null;
  machineDetailedValuesPending: boolean;
  machineDetailedValuesError: StateError;
  error: StateError;
  paginatedMachines: PaginatedMachines | null;
  paginatedMachinesPending: boolean;
  paginatedMachinesError: StateError;
  campaignReportPending: boolean;
  campaignJsonDetailReports: ICampaignJsonDetailReportDict;
  campaignReports: ICampaignReportDict;
  currentDailyReport: DailyMachineReport | null;
}

export const initialState: State = {
  machinesList: null,
  machinesListPending: false,
  machinesListError: null,
  machines: {} as IMachineDict,
  machinePending: false,
  machineError: null,
  measurementValues: [],
  measurementValuesPending: false,
  operatingValues: [],
  operatingValuesPending: false,
  operatingAnalysis: null,
  operatingAnalysisPending: false,
  operatingAnalysisError: null,
  machineDetailedValues: null,
  machineDetailedValuesPending: false,
  machineDetailedValuesError: null,
  error: null,
  paginatedMachines: null,
  paginatedMachinesPending: false,
  paginatedMachinesError: null,
  campaignReportPending: false,
  campaignJsonDetailReports: {},
  campaignReports: {},
  currentDailyReport: null,
};

export function reducer(state = initialState, action: AnalyticsActions.Actions): State {
  switch (action.type) {
    case AnalyticsActions.MACHINES_LIST: {
      return { ...state, machinesListPending: true };
    }
    case AnalyticsActions.MACHINES_LIST_SUCCESS: {
      return {
        ...state,
        machinesList: action.payload,
        machinesListError: null,
      };
    }
    case AnalyticsActions.MACHINES_LIST_FAILURE: {
      return { ...state, machinesListError: action.payload };
    }
    case AnalyticsActions.MACHINES_LIST_COMPLETE: {
      return { ...state, machinesListPending: false };
    }

    case AnalyticsActions.MEASUREMENT_VALUES: {
      return { ...state, measurementValuesPending: true };
    }
    case AnalyticsActions.MEASUREMENT_VALUES_SUCCESS: {
      return {
        ...state,
        measurementValues: [...state.measurementValues, ...action.payload],
        error: null,
      };
    }
    case AnalyticsActions.MEASUREMENT_VALUES_FAILURE: {
      return { ...state, error: action.payload };
    }
    case AnalyticsActions.MEASUREMENT_VALUES_COMPLETE: {
      return { ...state, measurementValuesPending: false };
    }

    case AnalyticsActions.OPERATING_VALUES: {
      return { ...state, operatingValuesPending: true };
    }
    case AnalyticsActions.OPERATING_VALUES_SUCCESS: {
      return {
        ...state,
        operatingValues: [...state.operatingValues, ...action.payload],
        error: null,
      };
    }
    case AnalyticsActions.OPERATING_VALUES_FAILURE: {
      return { ...state, error: action.payload };
    }
    case AnalyticsActions.OPERATING_VALUES_COMPLETE: {
      return { ...state, operatingValuesPending: false };
    }

    case AnalyticsActions.MACHINE: {
      return { ...state, machinePending: true };
    }
    case AnalyticsActions.MACHINE_SUCCESS: {
      const machinesDict = { ...state.machines };
      machinesDict[action.payload.id] = action.payload;
      return {
        ...state,
        machines: machinesDict,
        machineError: null,
      };
    }
    case AnalyticsActions.MACHINE_FAILURE: {
      return { ...state, machineError: action.payload };
    }
    case AnalyticsActions.MACHINE_COMPLETE: {
      return { ...state, machinePending: false };
    }

    case AnalyticsActions.OPERATING_ANALYSIS: {
      return { ...state, operatingAnalysisPending: true };
    }
    case AnalyticsActions.OPERATING_ANALYSIS_SUCCESS: {
      return {
        ...state,
        operatingAnalysis: action.payload,
        operatingAnalysisError: null,
      };
    }
    case AnalyticsActions.OPERATING_ANALYSIS_FAILURE: {
      return { ...state, operatingAnalysisError: action.payload };
    }
    case AnalyticsActions.OPERATING_ANALYSIS_COMPLETE: {
      return { ...state, operatingAnalysisPending: false };
    }

    case AnalyticsActions.DETAILED_VALUES: {
      return { ...state, machineDetailedValuesPending: true };
    }
    case AnalyticsActions.DETAILED_VALUES_SUCCESS: {
      const machineDetailedValues = { ...state.machineDetailedValues };
      machineDetailedValues[action.payload.machineId] = action.payload;

      return {
        ...state,
        machineDetailedValues: machineDetailedValues,
        machineDetailedValuesError: null,
      };
    }
    case AnalyticsActions.DETAILED_VALUES_FAILURE: {
      return { ...state, machineDetailedValuesError: action.payload };
    }
    case AnalyticsActions.DETAILED_VALUES_COMPLETE: {
      return { ...state, machineDetailedValuesPending: false };
    }

    // paginated Machines
    case AnalyticsActions.PAGINATED_MACHINES: {
      return { ...state, paginatedMachinesPending: true };
    }
    case AnalyticsActions.PAGINATED_MACHINES_SUCCESS: {
      return {
        ...state,
        paginatedMachines: action.payload,
        paginatedMachinesPending: false,
      };
    }
    case AnalyticsActions.PAGINATED_MACHINES_FAILURE: {
      return { ...state, paginatedMachinesError: action.payload };
    }
    case AnalyticsActions.PAGINATED_MACHINES_COMPLETE: {
      return { ...state, paginatedMachinesPending: false };
    }

    // campaign report JSON export
    case AnalyticsActions.GET_JSON_CAMPAIGN_REPORT: {
      return {
        ...state,
        campaignReportPending: true,
      };
    }

    case AnalyticsActions.GET_JSON_CAMPAIGN_REPORT_SUCCESS: {
      const campaignJsonDetailReports = { ...state.campaignJsonDetailReports };
      campaignJsonDetailReports[action.campaignReportId] = action.campaignJsonDetailReport;
      return {
        ...state,
        campaignJsonDetailReports,
      };
    }

    case AnalyticsActions.GET_JSON_CAMPAIGN_REPORT_FAILURE: {
      return {
        ...state,
      };
    }

    case AnalyticsActions.GET_JSON_CAMPAIGN_REPORT_COMPLETE: {
      return {
        ...state,
        campaignReportPending: false,
      };
    }

    // campaign report
    case AnalyticsActions.GET_CAMPAIGN_REPORT: {
      return {
        ...state,
        campaignReportPending: true,
      };
    }

    case AnalyticsActions.GET_CAMPAIGN_REPORT_SUCCESS: {
      const campaignReports = { ...state.campaignReports };
      campaignReports[action.campaignReport.id] = action.campaignReport;
      return {
        ...state,
        campaignReports,
      };
    }

    case AnalyticsActions.GET_JSON_CAMPAIGN_REPORT_FAILURE: {
      return {
        ...state,
      };
    }

    case AnalyticsActions.GET_JSON_CAMPAIGN_REPORT_COMPLETE: {
      return {
        ...state,
        campaignReportPending: false,
      };
    }

    case AnalyticsActions.SET_CURRENT_DAILY_REPORT: {
      return {
        ...state,
        currentDailyReport: action.dailyReport,
      };
    }

    case AnalyticsActions.RESET_ANALYTICS_DETAILS_DATA: {
      return {
        ...state,
        operatingAnalysis: null,
        machineDetailedValues: null,
      };
    }

    case AnalyticsActions.RESET_ANALYTICS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getMachinesList = (state: State) => state.machinesList;
export const getMachinesListPending = (state: State) => state.machinesListPending;
export const getMachinesListError = (state: State) => state.machinesListError;
export const getMeasurementValues = (state: State) => state.measurementValues;
export const getOperatingValues = (state: State) => state.operatingValues;
export const getCombinedValues = (state: State) => {
  const combinedValues: any[] = [];
  const ids = [
    ...new Set([
      ...state.measurementValues.map((measurementValue) => measurementValue.machineId),
      ...state.operatingValues.map((operatingValue) => operatingValue.machineId),
    ]),
  ];

  ids.forEach((id) => {
    const value = {
      ...state.measurementValues.find((mmVal: any) => mmVal.machineId === id),
      ...state.operatingValues.find((moVal: any) => moVal.machineId === id),
    };
    if (Object.keys(value).length > 0) {
      combinedValues.push(value);
    }
  });

  return combinedValues;
};

export const getCombinedValuesPending = (state: State) => state.measurementValuesPending || state.operatingValuesPending;
export const getMachines = (state: State) => state.machines;
export const getMachinePending = (state: State) => state.machinePending;
export const getMachineError = (state: State) => state.machineError;

export const getOperatingAnalysis = (state: State) => state.operatingAnalysis;
export const getOperatingAnalysisPending = (state: State) => state.operatingAnalysisPending;
export const getOperatingAnalysisError = (state: State) => state.operatingAnalysisError;

export const getMachineDetailedValues = (state: State) => state.machineDetailedValues;
export const getMachineDetailedValuesPending = (state: State) => state.machineDetailedValuesPending;
export const getMachineDetailedValuesError = (state: State) => state.machineDetailedValuesError;

export const getPaginatedMachines = (state: State) => state.paginatedMachines;

export const getCampaignReportPending = (state: State) => state.campaignReportPending;
export const getCampaignJsonDetailReports = (state: State) => state.campaignJsonDetailReports;
export const getCampaignReports = (state: State) => state.campaignReports;

export const getCurrentDailyReport = (state: State) => state.currentDailyReport;
